/**
 * Our global data/state-store
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * https://medium.com/@samuelresua/global-state-with-react-context-cfa99946900d
 */
import React, {cloneElement} from 'react';
import {Globals} from './Providers/Globals';

// Collecting the providers
const providers = [
    <Globals.Provider key={1}/>,
];

// Store-Wrapper
const Store = ({children: initial}) => providers.reduce(
    (children, parent) => cloneElement(parent, {children}),
    initial
);

// Export everything
export {
    Store,
    Globals
};
