/**
 * Our scrolling hero state
 */
import React from 'react';
import PropTypes from 'prop-types';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'dimLogo':
            return {
                ...state,
                logo: true,
            };
        case 'showLogo':
            return {
                ...state,
                logo: false,
            };
        case 'toggleLogo':
            return {
                ...state,
                logo: !state.logo,
            };
        case 'dimHero':
            return {
                ...state,
                hero: true,
            };
        case 'showHero':
            return {
                ...state,
                hero: false,
            };
        case 'toggleHero':
            return {
                ...state,
                hero: !state.hero,
            };
        case 'setLogoHeight':
            return {
                ...state,
                logoHeight: action.logoHeight,
            };
        case 'setHeroHeight':
            return {
                ...state,
                heroHeight: action.heroHeight,
                containerHeight: action.containerHeight,
            };
        default:
            return state;
    }
};


const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {logo: false, hero: false, logoHeight: '0px', heroHeight: '0px'});

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.elementType),
    ]),
};

// Export
export const Globals = {
    State,
    Dispatch,
    Provider,
};
