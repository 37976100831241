/**
 * Wrapping the root element in our providers
 */
// NPM
import React from 'react';

// Peritus
import {Store} from './Store';

export const wrapRootElement = ({element}) => (<Store>{element}</Store>);
